import React from "react";
import { graphql } from "gatsby";
import { path } from "ramda";
import AboutBox from "../components/About/AboutBox";
import Email from "../components/Email/AdventureEmail";
import travel from "../data/Travel";
import { UserTopic } from "../styles/indexStyle.js";
import Layout from "../components/layout";
import styled from "styled-components";
import mountain from "../assets/images/mountain.jpg";
import kiteboardingrules from "../assets/images/kiteboardingrules.jpg";
import elCapitan from "../assets/images/el-capitain.jpg";
import surfHawaii from "../assets/images/surf-hawaii.jpg";

const Header = styled.div`
  background-image: url(${mountain});
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: auto;
  background-position: bottom;
  padding: 5% 8%;
  position: relative;
  @media (max-width: 700px) {
    min-height: 100vh;
    height: auto;
  }
`;

const SearchBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80%;
`;

const Title = styled.h1`
  font-size: 4.2em;
  font-weight: bold;
  color: #ffffff;
  align-self: center;
  @media (max-width: 700px) {
    font-size: 3em;
  }
`;

const SubTitle = styled.h3`
  font-weight: bold;
  color: #ffffff;
  align-self: center;
`;

const BodyWrapper = styled.div`
  margin: auto;
  background-color: #ffffff;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: rgba(255, 255, 255, 1);
  border-style: none none solid none;
  border-width: 3px;
  border-color: #3023ae;
  border-radius: 3px;
  width: 60%;
  max-width: 850px;
  height: 315px;
  margin: auto;
  margin-bottom: 10px;
  padding: 15px 0;
  :nth-child(even) {
    flex-direction: row-reverse;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: 90%;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin: auto;
`;

const RowImage = styled.img`
  width: 400px;
  height: 280px;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15),
    0 2px 4px 0 rgba(93, 100, 148, 0.2);
`;

const Divider = styled.hr`
  height: 1px;
  width: 90%;
  color: #000000;
  margin: 50px auto;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0 0 0;
`;

const TextBoxTitle = styled.h1`
  font-size: 1.2em;
  font-weight: bold;
  color: #3023ae;
`;

const AdventureTitle = styled.h2`
  font-size: 4.2em;
  font-weight: bold;
  color: #3023ae;
  text-align: center;
  margin-top: 25px;
  @media (max-width: 700px) {
    font-size: 3em;
  }
`;

const IndexPage = (props) => {
  const image = path(["data", "file", "childImageSharp", "fixed"], props);
  return (
    <Layout noBackground>
      <Header>
        <SearchBox>
          <Title>Adventure Sports Travel</Title>
          <SubTitle>Your Adventure Starts Here</SubTitle>
        </SearchBox>
      </Header>
      <BodyWrapper>
        <AdventureTitle>Imagine Your Next Adventure</AdventureTitle>
        <Divider />
        <ColumnWrapper>
          <RowWrapper>
            <RowImage src={kiteboardingrules} />
            <TextBox>
              <TextBoxTitle>
                Catching Epic Wind Kiteboarding in Eygpt
              </TextBoxTitle>
              <p>
                Kiteboarding trip all over the world. Meetup with top companies
                and instructors that can help make your dreams become a reality.
              </p>
            </TextBox>
          </RowWrapper>
          <RowWrapper>
            <RowImage src={elCapitan} />
            <TextBox>
              <TextBoxTitle>
                Rock Climbing the World Famous El Capitan
              </TextBoxTitle>
              <p>
                Find guides and other rock climbing enthusiast to climb the
                worlds best mountains.
              </p>
            </TextBox>
          </RowWrapper>
          <RowWrapper>
            <RowImage src={surfHawaii} />
            <TextBox>
              <TextBoxTitle>Riding Monster Blue Waves in Hawaii</TextBoxTitle>
              <p>
                Ride the bigest waves or learn to surf at hundreds of the best
                surf beaches in the world.
              </p>
            </TextBox>
          </RowWrapper>
        </ColumnWrapper>
        <Divider />
        <UserTopic>
          {travel.map((item) => (
            <AboutBox key={item.id} info={item} />
          ))}
        </UserTopic>
      </BodyWrapper>
      <Divider />
      <Email image={image} />
      <Divider />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    file(relativePath: { eq: "email-envelope-mail.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
