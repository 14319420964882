export default [
  {
    id: 1,
    title: "Sports",
    description:
      "Search for the Sports you love and book adventures with the best companies.",
  },
  {
    id: 2,
    title: "Destinations",
    description: "Already booked a trip? Find adventures at your destination.",
  },
  {
    id: 3,
    title: "Book",
    description:
      "Need a place to stay? Book flights, hotels and transportation right here.",
  },
];
